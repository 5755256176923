.communities_box {
    text-align:center;
    color: #102455;
    /* height: 100%; */
  }

  .community_announce > a{
    margin-left: 10px;
    text-decoration: none;
    color: #102455;
    font-weight: bold;
  }
  .communities_box_margin {
    margin-top:200px;
  }
  
  /* ulに横並びかつ折り返し */
  .communities_list{
      display:flex;
      justify-content: center;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 0;
      /* text-align:center; */
      width:1000px;
      margin: 100px auto;
    }
  
  /* liブロックの装飾 */
  .community_section{
      width:170px;
      margin: 14px 20px;
      /* box-sizing: border-box;
      border: dotted 2px #FFF; */
      border-radius: 16px;
      background:#0D47A1;
      box-shadow: 0px 0px 0px 4px #0D47A1;
    }
  
  /* li内の写真のcss */
  .community_section img{
      /* display:block;
      padding: 6px;
      color:#FFF;
      text-decoration:none; */
      width:100%;
      height: 100%;
      border-radius: 16px;
    }
  
    /*checkした時に作られるクラスのcss  */
  /* .community_section img.checked {
      border: 6px solid blue;
      box-sizing: border-box;
    } */
  
    /* チェックボックスの位置 
  .community_section .obejectives-checkbox {
      position: absolute;
      top: 12px;
      right: 12px;
      display: none;
    } */
  /* checkされた時のチェックボックス */
  /* .image-box img.checked + .obejectives-checkbox {
      display: block;
    } */
  
  