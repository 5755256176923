.likeninlogin_h2{
    margin-top:150px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #102455;
}
.likeninlogin_box{
    margin-top:200px;
    width: 280px;
    margin: 120px auto;
}

.likeninlogin_box img{
    width:100%;
}