.select_mtg_box {
    text-align:center;
}

.select_mtg_h2{
    margin-top: 50px;
    color: #393b3e;
}
.select_mtg_h4{
    color: white;
}

.reserved{
    background-color:#1740aa;
    padding: 3px;
    border-radius: 3px;
}

.unreserved{
    background-color:#00b4cc;
    padding: 3px;
    border-radius: 3px;
}

.mtg_options{
    width: 150px;
    height: 30px;
}
.checked{
    background-color:#1740aa;
    width: 200px;
    height: 30px;
    margin-top: 30px;
    border-radius: 15px;
    color: white;
    font-weight: bold;
}
.unchecked{
    background-color:#00b4cc;
    width: 200px;
    height: 30px;
    margin-top: 30px;
    border-radius: 15px;
    color: white;
    font-weight: bold;
}

