.upcomig_mtg_h3{
    color: #102455;
    font-size: 30px;
    font-weight: bold;
    padding-left: 100px;
}

.not_yet_upcoming_mtg{
    padding-left: 100px;
}

.not_yet_match_community{
    padding-left: 100px;
}
.upcoming_mtg_detail{
    width: 400px;
    background-color: #DDFCFE;
    margin: 20px auto;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
}

.dashboard_mtg_button{
    margin: 10px auto 30px;
}

.matched_user_images_section{
    display: flex;
    justify-content: space-evenly;
    margin: 50px 0px;
}

.matched_user_image{
    width:120px;
    height:120px;
    margin-right: 40px;
}

.matched_user_image img{
    width: 100%;
    object-fit:cover;
    border-radius:50%;
}


.profile_list{
    display: flex;
    justify-content: space-around;
}

.dash_h3{
    color: #102455;
    font-size: 30px;
    font-weight: bold;
    padding-left: 100px;
}

.user_match_li{
    width:120px;
    height:120px;
    margin-right: 40px;
}

.user_match_li img{
    width: 100%;
    object-fit:cover;
    border-radius:50%;
}


.matches_communities_ul{
    list-style: none;
    display: flex;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 60px;
}

.user_community_li{
    width: 150px;
    /* margin-left: 20px; */
    margin-right: 40px;
}

.user_community_li img{
    width: 100%;
    border-radius: 8px;
}

/* .user_applied_mtg_section{
    width: 400px;
    height: 450px;
    background-color: #DDFCFE;
    margin: 30px auto;
    text-align: center;
} */

.user_applied_meeting_ul{
    list-style: none;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 60px;
}

.user_aplmtg_li{
    width: 250px;
    margin: 0 auto;
    background-color: #68F2F4;
    border-radius: 8px;
    padding: 5px;
    color: white;
    font-weight: bold;
    margin-top: 20px;
}

.dashboard_add_button{   
    text-align: center;    
    text-decoration: none;   
    margin-bottom: 30px;
}

.dashboard_add_button_link{
    background-color: #102455;
    padding: 5px;
    text-decoration: none;
    color: white;
    font-weight: bold;   
    border-radius: 8px;
}



