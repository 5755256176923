.jobtitle_h4{
  color: #102455;
  margin-left: 56px;
  height: 26px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
}

.jobtitle_select {
    margin:90px auto 0px;
    width: 500px;
    text-align: center;
    background-color: white;
}

.jobtitle_select select{
  background-color: white;
  width: 100%;
  border-radius: 30px;
  height: 32px;
  color: #102455; 
  text-indent: 200px;
  font-weight: bold;
  border: 2px solid #102455;
  font-size: 20px;
  cursor: pointer;
  
}


.jobtitle_select select:focus {
  box-shadow: 0 0 10px 0; 
  outline: 0;
}
