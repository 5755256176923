.chat_wrapper{
    width:100%;                                            
    height:100vh;
}

.chat{
    width:100%; 
    height: 90%;
}

.str-chat{
    height: 88% !important;
}
.str-chat-channel{
    height: 88% !important;
    
}
/* .str-chat__channel-list-team {
    height: 400px !important;
} */

