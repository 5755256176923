.objectives_jobtitle_h2{
  margin: 60px auto 16px;
  height: 62px;
  width: 608px;
  color: #112455;
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 31px;
}

.objectives_h4{
  color: #102455;
  margin-left: 56px;
  height: 26px;
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
}



.objectives_selections {
  width: 600px;
  margin: 80px auto 0px;
}

.objectives_selections>.css-yk16xz-control{
  border-radius: 30px;
  border: 2px solid #102455;
}

.css-g1d714-ValueContainer>.css-1rhbuit-multiValue{
  background-color: #68F2F4 !important;
  border-radius: 8px !important;
}
/* ulに横並びかつ折り返し */
.objectives_list{
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
    /* text-align:center; */
    width:1000px;
    margin: 100px auto;
  }

/* liブロックの装飾 */
.objective_section{
    width:170px;
    margin: 14px 20px;
    /* box-sizing: border-box;
    border: dotted 2px #FFF; */
    border-radius: 8px;
    background:#0D47A1;
    box-shadow: 0px 0px 0px 4px #0D47A1;
  }

/* li内の写真のcss */
.objective_section img{
    /* display:block;
    padding: 6px;
    color:#FFF;
    text-decoration:none; */
    width:100%;
    height: 100%;
  }

  /*checkした時に作られるクラスのcss  */
.objective_section img.checked {
    border: 6px solid blue;
    box-sizing: border-box;
  }

  /*チェックボックスの位置  */
.objective_section .obejectives-checkbox {
    position: absolute;
    top: 12px;
    right: 12px;
    display: none;
  }
/* checkされた時のチェックボックス */
.image-box img.checked + .obejectives-checkbox {
    display: block;
  }


.objectives_button {
  width: 150px;
  height: 30px;
  margin: 80px auto 0px;
  background-color:#68f2f4;
  box-shadow: 0 0 5px 0;
  border-bottom: 5px solid #00b4cc;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.objectives_button:hover{
  background: #6acfe0;
  border-bottom: 2px solid #00b4cc;
}

.objectives_link{
  color: #fff !important;
  width: 100%;
  text-decoration: none;
  font-weight: bold;
  }