.header{
    display: flex;
    justify-content: space-between;
    border-bottom:groove 1px rgba(51, 51, 51, 0.16);
}

.dashboard_link{
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard_link img{
    width:200px;
}

.user_profile_link{
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.user_profile_link img{
    width: 100%;
    object-fit:cover;
    border-radius:50%;
}