.potlatch_mtg_h1{
    width: 600px;
    margin: 200px auto;
    text-align: center;
}

.potlatch_mtg_back_button{
    width: 200px;
    margin: 200px auto;
    text-align: center;
}

.potlatch_mtg_wrapper{
    display: flex;   
}

.mtg_screen{
    width: 70%;
}

.chat_space{
    width: 30%;
}

.potlatch_mtg_partner_bio{

}

.potlatch_mtg_partner_nav{
    height: 80px;
    display: flex;
    align-items: center;
}

.potlatch_mtg_partner_image{
    width:70px;
    height:70px;
    margin-right: 20px;
}

.potlatch_mtg_partner_image img{
    width: 100%;
    object-fit:cover;
    border-radius:50%;
}

.potlatch_mtg_partner_name{
    margin-right: 100px;

}

.chat_space .chat_wrapper{
    height: 450px;
}

.chat_space .str-chat-channel-list.messaging, .str-chat-channel-list.team, .str-chat-channel-list.commerce {
    position: fixed !important;
    left: -380px !important;
    top: 0 !important;
    z-index: 1001 !important;
    overflow-y: auto !important;
    box-shadow: 7px 0 9px 0 rgb(0 0 0 / 3%), 1px 0 0 0 rgb(0 0 0 / 3%) !important;
    transition: left 200ms ease-in-out !important;
}

.chat_space .str-chat-channel.messaging .str-chat__main-panel {
    padding: 5px 5px 0 !important;
}

.chat_space .messaging.str-chat .str-chat__header-livestream {
    padding-left: 20px!important;
    padding-right: 20px!important;
}


.chat_space .messaging.str-chat .str-chat__list {
    padding: 0 10px 0!important;
}

.chat_space .messaging.str-chat .str-chat__list-notifications {
    padding: 0 10px!important;
}

.chat_space .str-chat__send-button {
    display: none!important;
}


@media screen and (max-width: 959px) {
    /* 959px以下に適用されるCSS（タブレット用） */
    .potlatch_mtg_wrapper{
        display: block!important; 
    }

    .mtg_screen{
        width: 90%;
        height: 650px;
        margin: 10px auto;
    }

    .chat_space{
        width: 90%;
        margin: 30px auto;
    }
    
}