.profile_box{
    text-align: center;
}

.profile_image{
    width: 160px;
    height: 160px;
    margin: 40px auto 0px;
}
.profile_image img{
    width: 100%;
    border-radius: 80px;
}

.profile_list_common{
    margin-top: 40px;
    color: #393b3e;
    font-weight: bold;
    margin-bottom: 10px;
}


.profile_link{
    text-decoration: none;
    background-color: #dddada;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    padding: 3px;
}