.location_wrapper {
    text-align:center;
}

.location_box{
    width: 540px;
    height: 520px;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid #102455;
    border-radius: 10px;
}

.location_logo img{
    width:280px;
}

.location_h1{
    color:#102455;
}

.location_reason_p{
    color: #102455;
    font-size: 23px;
}

.country_city{
    margin-top:70px;
}

.location_input {
    margin-top:20px;
}

#user_location{
    width: 280px;
    font-size: 24px;
    box-shadow: 0 0 5px 0;
    font-weight: bold;
    color: #393b3e;
    background-color: #dddada;
    border: 1px solid #102455;
    border-radius: 10px; 
    text-align: center;
}

#user_location:focus {
    box-shadow: 0 0 10px 0; 
    outline: 0;
}

.navigation_wrapper{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 80px;
}

